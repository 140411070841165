module.exports = [{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://4985d1847baeb6349ba7972a4b87b0cf@o4506581949349888.ingest.sentry.io/4506581950857216"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"withWebp":true,"quality":100,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"className":"markdown-header-link","offsetY":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PG86LMK","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-111191498-2","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pragmatic Pineapple Blog","short_name":"Pragmatic Pineapple","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"minimal-ui","icon":"content/assets/pineapple-emoji.png","icons":[{"src":"content/assets/icons/maskable_icon_x48.png","sizes":"48x48","type":"image/png","purpose":"any maskable"},{"src":"content/assets/icons/maskable_icon_x72.png","sizes":"72x72","type":"image/png","purpose":"any maskable"},{"src":"content/assets/icons/maskable_icon_x96.png","sizes":"96x96","type":"image/png","purpose":"any maskable"},{"src":"content/assets/icons/maskable_icon_x128.png","sizes":"128x128","type":"image/png","purpose":"any maskable"},{"src":"content/assets/icons/maskable_icon_x192.png","sizes":"192x192","type":"image/png","purpose":"any maskable"},{"src":"content/assets/icons/maskable_icon_x384.png","sizes":"384x384","type":"image/png","purpose":"any maskable"},{"src":"content/assets/icons/maskable_icon_x512.png","sizes":"512x512","type":"image/png","purpose":"any maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"52fa40d00c212403c9b0afbfb2fe749b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
